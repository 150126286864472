import { defaultValue } from "../context/context";
import {
  baseURLAbiturient,
  baseURLStudent,
  baseURLEmployee,
  baseURLJob,
  baseAuthLoginURL,
} from "../Api/config";
import AbiturientIcon from "../assets/Icons/Abiturient";
import StudentIcon from "../assets/Icons/Student";
import WorkIcon from "../assets/Icons/Work";
import ExitIcon from "../assets/Icons/ExitIcon";

export const lkList: {
  name: string;
  nameEn: string;
  value: keyof typeof defaultValue | "logOut";
  url: string;
  icon: any;
}[] = [
  {
    name: "Личный кабинет поступающего",
    nameEn: "Applicant's personal account",
    value: "abiturient",
    url: baseURLAbiturient,
    icon: AbiturientIcon(),
  },
  {
    name: "Личный кабинет студента",
    nameEn: "Student personal account",
    value: "student",
    url: baseURLStudent,
    icon: StudentIcon(),
  },
  {
    name: "Личный кабинет работника",
    nameEn: "Employee personal account",
    value: "employee",
    url: baseURLEmployee,
    icon: WorkIcon(),
  },
  {
    name: "Цифровая кадровая платформа",
    nameEn: "Digital HR platform",
    value: "job",
    url: baseURLJob,
    icon: WorkIcon(),
  },
  {
    name: "Выйти",
    nameEn: "Log out",
    value: "logOut",
    url: baseAuthLoginURL,
    icon: ExitIcon(),
  },
];
