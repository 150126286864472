import { Button, Col, Row } from "react-bootstrap";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../Accounts/Login/Login.module.scss";
import HeaderLink from "../Header/HeaderLink";
import { DataContext } from "../../context/context";
import { lkList } from "../../utils/data";
import s from "./EnterLk.module.scss";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { handleLogout } from "../../Api/http/userApi";

const EnterLk = () => {
  const { t, i18n } = useTranslation();
  const [data] = useContext(DataContext);

  const navigate = useNavigate();
  useEffect(() => {
    if (data.email === "") navigate("/");
  }, [data]);

  // const currentHash = window.location.hash.substring(1);
  // console.log(currentHash);
  // if (currentHash.includes("lk_abit/?course=")) {
  //   console.log("here");
  //   navigate(`${currentHash}`);
  // }

  return (
    <Row className="vh-100">
      <Col
        lg={4}
        xs={12}
        className="d-flex justify-content-center align-items-center"
      >
        <div className={styles.auth} style={{ maxWidth: "280px" }}>
          <div className="mb-5">
            <HeaderLink />
          </div>
          <h2 className={`${styles.user} text-center mb-6`}>
            {t("authorization")}
          </h2>
          <p className="hint descr-info text-center mb-5">
            {t("selectAccount")}
          </p>
          {lkList.map((lk, index) => {
            if (lk.value === "logOut" || data[lk.value])
              return (
                <Button
                  key={index}
                  variant="secondary"
                  size="lg"
                  className={`w-100 mb-3 ${s.button}`}
                  style={{ fontSize: "14px", fontWeight: "400" }}
                  onClick={() => {
                    if (lk.value === "logOut") {
                      handleLogout();
                    } else {
                      navigate(lk.url);
                      window.location.reload();
                    }
                  }}
                >
                  <Row className="d-flex align-items-center">
                    <Col xs={1}>{lk.icon}</Col>
                    <Col>{i18n.language === "ru" ? lk.name : lk.nameEn}</Col>
                  </Row>
                </Button>
              );
          })}
          <ChangeLanguage />
        </div>
      </Col>
      <Col lg={8} className={styles.banner} />
    </Row>
  );
};

export { EnterLk };
